import React from "react"
import { Link, graphql } from "gatsby"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import { Row, Col, Pagination } from "react-bootstrap"

import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"
import SEO from "../components/seo"

class BlogList extends React.Component {
  render() {
    const { currentPage, numPagesBlog } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPagesBlog
    const prevPage =
      currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()
    const { data } = this.props

    let items = []
    for (let number = 1; number <= 5; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage}>
          <Link to={`/blog/${number}`}>{number}</Link>
        </Pagination.Item>
      )
    }

    return (
      <Layout>
        <SEO
          title={`Outdoor Living Space Idea & Design Blog`}
          description="Discover new and exciting ideas for your outdoor living space, backyard design, innovative work space enhancements, and more from Walpole Outdoors."
        />
        <InnerWrapper>
          <div className="page-hero"></div>

          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/blog">Blog</Link>
            </Breadcrumb.Item>
          </Breadcrumb>

          <Row className="blogarchive">
            <Col md={12}>
              {data.allBlog.edges.map(({ node }, index) => {
                if (index === 0 && currentPage === 1) {
                  return (
                    <article className="blog-featured">
                      <div className="post-thumbnail">
                        <Link to={`/blogs/${node.seoURL}`}>
                          {node.imagePaths.length > 0 ? (
                            <img src={node.imagePaths[0]} />
                          ) : (
                            <img src="" />
                          )}
                        </Link>
                      </div>
                      <h2 className="generalheader blogtitle">
                        <Link to={`/blogs/${node.seoURL}`}>{node.title}</Link>
                      </h2>
                      <hr className="bloglisthr" />
                      <div className="entry-meta">
                        <span className="posted-on">Posted on </span>
                        {node.postedDate} | Category {node.category}
                      </div>

                      <div className="entry-content blogsummary blog-index-blog">
                        <HTMLEllipsis
                          unsafeHTML={node.content}
                          maxLine="3"
                          ellipsis="..."
                          basedOn="letters"
                        />
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: node.content,
                          }}
                        /> */}
                      </div>
                      <Link
                        className="blogreadmore"
                        to={`/blogs/${node.seoURL}`}
                      >
                        Read More...
                      </Link>
                    </article>
                  )
                }
                return (
                  <article className="blog-list" key={node.id}>
                    <Row>
                      <Col md={12}>
                        <article>
                          <div className="post-thumbnail">
                            <Link to={`/blogs/${node.seoURL}`}>
                              {node.imagePaths.length > 0 ? (
                                <img src={node.imagePaths[0]} />
                              ) : (
                                <img src="https://via.placeholder.com/1280x350" />
                              )}
                            </Link>
                          </div>
                          <h2 className="generalheader blogtitle">
                            <Link to={`/blogs/${node.seoURL}`}>
                              {node.title}
                            </Link>
                          </h2>
                          <hr className="bloglisthr" />
                          <div className="entry-meta">
                            <span className="posted-on">Posted on </span>
                            {node.postedDate} | Category {node.category}
                          </div>

                          <div className="entry-content blogsummary">
                            <HTMLEllipsis
                              unsafeHTML={node.content}
                              maxLine="3"
                              ellipsis="..."
                              basedOn="letters"
                            />
                            {/* <div
                              dangerouslySetInnerHTML={{
                                __html: node.content,
                              }}
                            /> */}
                          </div>
                          <Link
                            className="blogreadmore"
                            to={`/blogs/${node.seoURL}`}
                          >
                            Read More...
                          </Link>
                        </article>
                      </Col>
                    </Row>
                  </article>
                )
              })}
            </Col>
          </Row>
          <div className="blogpagination">
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Previous Page
              </Link>
            )}

            {Array.from({ length: numPagesBlog }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                to={`/blog/${i === 0 ? "" : i + 1}`}
              >
                {i + 1}{" "}
              </Link>
            ))}

            {!isLast && (
              <Link to={nextPage} rel="next">
                Next Page →
              </Link>
            )}
          </div>
        </InnerWrapper>
      </Layout>
    )
  }
}

export const query = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allBlog(
      sort: { fields: postedDate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          postedDate
          title
          id
          content
          category
          seoURL
          imagePaths
        }
      }
    }
  }
`

export default BlogList
